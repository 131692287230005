<template>
  <div>
    <v-app-bar color="primary" flat class="pl-4">
      <v-toolbar-title class="font-weight-bold white--text">
        {{ userDispName }} - 心理测评答题系统
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            large
            color="white"
            class="mr-1"
            v-bind="attrs"
            v-on="on"
            @click="isShowConfirmDialog = true"
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>中止测试</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <AppDialog
      v-model="isShowConfirmDialog"
      size="small"
      title="确定要中止测试吗？"
      color="error"
      action-text="退出"
      @confirm="scaLogoutConfirmed"
    >
      答题进度会自动保存
    </AppDialog>
  </div>
</template>

<script>
import AppDialog from "@/components/AppDialog";

export default {
  components: {
    AppDialog
  },

  data() {
    return {
      isShowConfirmDialog: false
    };
  },

  computed: {
    userDispName() {
      return this.$store.getters["sca/userDispName"];
    }
  },

  methods: {
    scaLogoutConfirmed() {
      this.$store.dispatch("sca/clearScaState");
      this.isShowConfirmDialog = false;
      this.$router.push({ name: "scalogin" });
    }
  }
};
</script>
